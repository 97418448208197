<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import type { PrimitiveProps } from 'radix-vue';

import { type ButtonVariants, buttonVariants } from '.';

interface ButtonProps extends PrimitiveProps {
  as?: string;
  destructive?: boolean;
  expand?: boolean;
  size?: ButtonVariants['size'];
  variant?: ButtonVariants['variant'];
  class?: HTMLAttributes['class'];
}

const props = withDefaults(defineProps<ButtonProps>(), {
  as: 'button',
});
</script>

<template>
  <component
    :is="as"
    :class="
      cn(
        buttonVariants({ destructive, expand, size, variant }),
        props.class ?? '',
      )
    "
  >
    <slot name="icon" />
    <span
      v-if="$slots.default"
      class="grow select-none truncate"
    >
      <slot />
    </span>
  </component>
</template>
